.infoBox {
    flex: 1;
    height: 100%;
    width: 100%;
  }
  
  .infoBox:not(:last-child) {
    margin-right: 10px;
  }
  
  .infoBox--selected {
    border-top: 10px solid #3CB7EF;
  }
  
  .infoBox--red {
    border-color: red;
  }
  
  .infoBox__cases--green {
    color: lightgreen !important;
  }
  
  .infoBox__title{
    color: Black;
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 0.2rem;
    height: 50px;
  }

  .infoBox__cases {
    color: #cc1034;
    font-weight: 600;
    font-size: 1.75rem;
    margin-bottom: 0.2rem;
  }
  
  .infoBox__total {
    color: #6c757d;
    font-weight: 700 !important;
    font-size: 0.8rem !important;
    margin-top: 10px !important;
  }

  .infoBox__text--red{
      color: red;
      font-size: 20px;
      font-weight: 300;
  }

  .infoBox__text--green{
    color: green;
    font-size: 20px;
    font-weight: 300;
}
  