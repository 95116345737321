* {
    margin: 0;
}  
body {
    background-color: #f5f6fa;
}

.main {
    background-color: #f5f6fa;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.main__header{
    color: #2C252B;
    margin: 10px;
    padding: 10px;
}

.main__left{
    display: block;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;  
}

.main__information{
    display: block;
    color: #6a5d5d;
    background-color:white;
    font-weight: 400;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    padding: 0px;
    height: 90%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.table__information > h3{
    display: block;
    color: #6a5d5d;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: left;
}

@media (max-width: 990px) {
    .app {
      flex-direction: column;
    }
}